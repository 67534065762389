import { ReactElement } from 'react';
import { SimpleUIComponent } from 'openbim-components';
import { createRoot } from 'react-dom/client';

export class MaterialUIContainer<T extends HTMLElement = HTMLElement> extends SimpleUIComponent<T> {
    constructor(components: any, id?: string) {
        super(components);
    }

    insertMaterialUIComponent(materialUIComponent: ReactElement) {
        const container = this.domElement;
        const root = createRoot(container);
        root.render(materialUIComponent);
        return root;
    }
}
