import { AlertColor } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit'

interface ISelectedModel {
    name: string;
    uuid: string;
}

type SliceState = {
    dialogs: {
        select: { open: boolean }
    };
    selectedIfcModel: ISelectedModel;
    loading: {
        isLoading: boolean,
        type: "" | "loadingModel" | "fetchingModel";
        content: string,
    };
    alertSnackbar: { open: boolean; message: string; severity: AlertColor };
    loadingSnackbar: { open: boolean; message: string; };
}

const initialState: SliceState = {
    dialogs: {
        select: { open: false }
    },
    selectedIfcModel: {
        name: "",
        uuid: "",
    },
    loading: {
        isLoading: false,
        type: "",
        content: "",
    },
    alertSnackbar: { open: false, message: "", severity: "success" },
    loadingSnackbar: { open: false, message: "" },
}

const ifcViewerSlice = createSlice({
    name: 'ifcViewer',
    initialState,
    reducers: {
        modelSelectDialogOpened: (ifcViewer) => {
            ifcViewer.dialogs.select.open = true
        },
        modelSelectDialogClosed: (ifcViewer) => {
            ifcViewer.dialogs.select.open = false
        },
        modelLoadingStarted: (ifcViewer, action) => {
            ifcViewer.loading.isLoading = true
            ifcViewer.loading.type = "loadingModel"
            ifcViewer.loading.content = action.payload
            ifcViewer.alertSnackbar = {
                open: true,
                message: "loading_model_started",
                severity: "info",
            };
            ifcViewer.loadingSnackbar = {
                open: true,
                message: "loading_model_element_started",
            };
        },
        modelLoadingFullfilled: (ifcViewer) => {
            ifcViewer.loading.isLoading = false
            ifcViewer.loading.type = ""
            ifcViewer.loading.content = ""
            ifcViewer.alertSnackbar = {
                open: true,
                message: "loading_model_fullfilled",
                severity: "success",
            };
            ifcViewer.loadingSnackbar = {
                open: false,
                message: "",
            };
        },
        modelLoadingFailed: (ifcViewer) => {
            ifcViewer.loading.isLoading = false
            ifcViewer.loading.type = ""
            ifcViewer.loading.content = ""
            ifcViewer.alertSnackbar = {
                open: true,
                message: "loading_model_failed",
                severity: "error",
            };
            ifcViewer.loadingSnackbar = {
                open: false,
                message: "",
            };
        },
        modelFetchingStarted: (ifcViewer, action) => {
            ifcViewer.loading.isLoading = true
            ifcViewer.loading.type = "fetchingModel"
            ifcViewer.loading.content = action.payload
            ifcViewer.alertSnackbar = {
                open: true,
                message: "fetching_model_started",
                severity: "info",
            };
            ifcViewer.loadingSnackbar = {
                open: true,
                message: "fetching_model_element_started",
            };
        },
        modelFetchingFullfilled: (ifcViewer) => {
            ifcViewer.loading.isLoading = false
            ifcViewer.loading.type = ""
            ifcViewer.loading.content = ""
            ifcViewer.alertSnackbar = {
                open: true,
                message: "fetching_model_fullfilled",
                severity: "success",
            };
            ifcViewer.loadingSnackbar = {
                open: false,
                message: "",
            };
        },
        modelFetchingFailed: (ifcViewer) => {
            ifcViewer.loading.isLoading = false
            ifcViewer.loading.type = ""
            ifcViewer.loading.content = ""
            ifcViewer.alertSnackbar = {
                open: true,
                message: "fetching_model_failed",
                severity: "error",
            };
            ifcViewer.loadingSnackbar = {
                open: false,
                message: "",
            };
        },
        modelSelected: (ifcViewer, action) => {
            ifcViewer.selectedIfcModel = action.payload
        },
        alertSnackbarOpened: (ifcViewer, action) => {
            ifcViewer.alertSnackbar = {
                open: true,
                message: action.payload.message,
                severity: action.payload.severity,
            };
        },
        alertSnackbarClosed: (ifcViewer) => {
            ifcViewer.alertSnackbar = {
                ...ifcViewer.alertSnackbar,
                open: false,
            };
        },
        loadingSnackbarOpened: (ifcViewer, action) => {
            ifcViewer.loadingSnackbar = {
                open: true,
                message: action.payload.message,
            };
        },
        loadingSnackbarClosed: (ifcViewer) => {
            ifcViewer.loadingSnackbar = {
                ...ifcViewer.loadingSnackbar,
                open: false,
            };
        },
    },
})

export const openSelectModelDialog = () => ({
    type: modelSelectDialogOpened.type,
})

export const closeSelectModelDialog = () => ({
    type: modelSelectDialogClosed.type,
})

export const setModelLoadingStarted = (modelName: string) => ({
    type: modelLoadingStarted.type,
    payload: modelName,
})

export const setModelLoadingFullfilled = () => ({
    type: modelLoadingFullfilled.type,
})

export const setModelLoadingFailed = () => ({
    type: modelLoadingFailed.type,
})

export const setModelFetchingStarted = (modelName: string) => ({
    type: modelFetchingStarted.type,
    payload: modelName,
})

export const setModelFetchingFullfilled = () => ({
    type: modelFetchingFullfilled.type,
})

export const setModelFetchingFailed = () => ({
    type: modelLoadingFailed.type,
})

export const selectModel = (selectedModel: ISelectedModel) => ({
    type: modelSelected.type,
    payload: selectedModel,
})

export const openAlertSnackbar = (payload: {
    message: string;
    severity: string;
}) => ({
    type: alertSnackbarOpened.type,
    payload,
});

export const closeAlertSnackbar = () => ({
    type: alertSnackbarClosed.type,
});

export const openLoadingSnackbar = (payload: {
    message: string;
}) => ({
    type: loadingSnackbarOpened.type,
    payload,
});

export const closeLoadingSnackbar = () => ({
    type: loadingSnackbarClosed.type,
});

export const {
    modelSelectDialogOpened,
    modelSelectDialogClosed,
    modelLoadingStarted,
    modelLoadingFullfilled,
    modelLoadingFailed,
    modelFetchingStarted,
    modelFetchingFullfilled,
    modelFetchingFailed,
    modelSelected,
    alertSnackbarOpened,
    alertSnackbarClosed,
    loadingSnackbarOpened,
    loadingSnackbarClosed,
} = ifcViewerSlice.actions

export default ifcViewerSlice.reducer

export const getSelectModelDialog = (state: any) =>
    state.ui.projects.selectedProject.ifcViewer.dialogs.select

export const getSelectedModel = (state: any) =>
    state.ui.projects.selectedProject.ifcViewer.selectedIfcModel

export const getisLoading = (state: any) =>
    state.ui.projects.selectedProject.ifcViewer.loading.isLoading

export const getLoading = (state: any) =>
    state.ui.projects.selectedProject.ifcViewer.loading

export const getAlertSnackbar = (state: any) =>
    state.ui.projects.selectedProject.ifcViewer.alertSnackbar;

export const getLoadingSnackbar = (state: any) =>
    state.ui.projects.selectedProject.ifcViewer.loadingSnackbar;