import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Paper from '@mui/material/Paper';
import TreeElement from "./treeElement/treeElement";

interface ITreeProps {
    spatialStructure: any;
    selectedIds: number[];
    selectItemsById: (ids: number[]) => Promise<void>;
    showHideItemsById: (show: boolean, expressIds: number[]) => Promise<void>;
    isItemVisibleById: (expressId: number) => Promise<boolean>;
}

interface ITreeElementProps {
    element: any;
}

const Tree: React.FC<ITreeProps> = ({
    spatialStructure,
    selectItemsById,
    selectedIds,
    showHideItemsById,
    isItemVisibleById,
}) => {
    const { t } = useTranslation('ifc_viewer', {
        keyPrefix: 'tree',
    })

    const handleSelect = async (event: React.SyntheticEvent, nodeIds: string[]) => {
        const idsNumberArray = nodeIds.map(Number);
        await selectItemsById(idsNumberArray);
    };

    const TreeStructure: React.FC = () => {
        if (spatialStructure) {
            return (
                <TreeElement
                    nodeId={spatialStructure.expressID.toString()}
                    label={spatialStructure.type}
                    ContentProps={{
                        showHideItemsById,
                        isItemVisibleById
                    }}
                >
                    <TreeElementCreator element={spatialStructure.children[0]} />
                </TreeElement>
            );
        } else {
            return (
                <></>
            );
        }
    }

    const TreeElementCreator: React.FC<ITreeElementProps> = ({ element }) => {
        if (element.children && element.children.length === 0) {
            return (
                <TreeElement
                    nodeId={element.expressID.toString()}
                    label={element.type}
                    ContentProps={{
                        showHideItemsById,
                        isItemVisibleById
                    }}
                />
            );
        } else {
            return (
                <TreeElement
                    nodeId={element.expressID.toString()}
                    label={element.type}
                    ContentProps={{
                        showHideItemsById,
                        isItemVisibleById
                    }}
                >
                    {element.children.map((child: any) => <TreeElementCreator key={child.expressID.toString()} element={child} />)}
                </TreeElement>
            );
        }
    }

    return (
        <Paper sx={{ backgroundColor: "transparent", color: "white" }}>
            <TreeView
                aria-label="icon expansion"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                multiSelect={true}
                onNodeSelect={handleSelect}
                selected={selectedIds.map(String)}
                sx={{
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                    "&& .Mui-selected": {
                        backgroundColor: '#768669 !important',
                    },
                    "&& .Mui-selected:hover": {
                        backgroundColor: '#768669 !important',
                    },
                    "&& .Mui-focused": {
                        backgroundColor: '#768669 !important',
                    },
                }}
            >
                <TreeStructure />
            </TreeView>
        </Paper>
    );
}

export default Tree;