import { Avatar, Box, Button, Chip, Container, IconButton, ListItemAvatar, ListItemText, Stack, Tooltip, useMediaQuery } from "@mui/material";
import { AddButton } from "./buttons/addButton";
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridValueGetterParams } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles'
import { useAppDispatch, useAppSelector } from "store";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getProjectPermissions } from "store/entities/projects/project.slice";
import { getLocales } from "store/entities/settings/settings.slice";
import { IIfcCreationLevelChoice, IIfcCreationStatusState, IIfcModelCreation, IStakeholderPermission } from "types";
import { useTranslation } from "react-i18next";
import DeleteIcon from '@mui/icons-material/Delete';
import { stringToColor } from "common/utils/helpers/stringToColor.helpers";
import { useState } from "react";
import { hasProjectPerm } from "services/authService";
import { projectPermissions } from "common/utils/constants/auth.constants";
import { LoadingSkeletonOverly, NoRowsOverlay } from "./table/overlays";
import { getIfcModelCreations, getIfcModelCreationsLevels, getIfcModelCreationsLoading, getIfcModelCreationsStates } from "store/entities/projects/ifcModelCreations/ifcModelCreations.slice";
import { fetchIfcModelCreations, fetchIfcModelCreationsLevelChoiches, fetchIfcModelCreationsOptimizationPriorityChoices, fetchIfcModelCreationsStatusStates } from "store/entities/projects/ifcModelCreations/ifcModelCreations.actions";
import { closeAlertSnackbar, closeLoadingSnackbar, getAlertSnackbar, getLoadingSnackbar, handleDeleteIfcModelCreationsDialogOpen, handleShowInfosIfcModelCreationsDialogOpen, selectIfcModelCreation } from "store/ui/projects/ifcModelCreations/ifcModelCreations.slice";
import AlertSnackbar from "common/components/snackbars/alertSnackbar";
import LoadingSnackbar from "common/components/snackbars/loadingSnackbar";
import IfcModelCreationDeleteDialog from "./dialogs/delete/deleteDialog";
import CreateIfcModelCreationsDialog from "./dialogs/create/createIfcModelCreationDialog";
import { fetchUploadedFiles } from "store/entities/projects/uploadedFiles/uploadedFiles.actions";
import ShowInfosIfcModelCreationsDialog from "./dialogs/showInfos/showInfosDialog";
import SearchIcon from '@mui/icons-material/Search';


const IfcModedlCreations: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme()
    const { t } = useTranslation("ifc_model_creations", {
        keyPrefix: "list.table",
    });

    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});

    const ifcModelCreations: IIfcModelCreation[] = useAppSelector(getIfcModelCreations);
    const ifcModelCreationsLevels = useAppSelector(getIfcModelCreationsLevels);
    const ifcModelCreationsStates = useAppSelector(getIfcModelCreationsStates);
    const loading = useAppSelector(getIfcModelCreationsLoading);
    const locales = useAppSelector(getLocales);
    const userPermissions: IStakeholderPermission[] = useAppSelector(
        getProjectPermissions
    );

    const { projectId } = useParams();

    interface ITableRowData {
        id: number;
        creation_id: number;
        creation_level: string;
        creation_status: string;
        base_model_file: {
            uuid: string;
            name: string;
        };
        created_model_file: {
            uuid: string;
            name: string;
        } | null;
        created_by: {
            email: string;
            first_name: string;
            last_name: string;
        };
        created_at: string;
    }

    const handleDeleteIfcModelCreationButtonClick = (rowData: ITableRowData) => {
        if (projectId !== undefined) {
            const index = ifcModelCreations.findIndex(
                (ifcModelCreation: IIfcModelCreation) => ifcModelCreation.id === rowData.creation_id
            );
            dispatch(selectIfcModelCreation(ifcModelCreations[index]));
            dispatch(handleDeleteIfcModelCreationsDialogOpen());
        }
    }

    const translatedColumnNames = {
        id: t("column_headers.id"),
        creation_level: t("column_headers.creation_level"),
        creation_status: t("column_headers.creation_status"),
        base_model_file: t("column_headers.base_model_file"),
        created_model_file: t("column_headers.created_model_file"),
        created_at: t("column_headers.created_at"),
        created_by: t("column_headers.created_by"),
        actions: t("column_headers.actions"),
    }

    const tableColumns: GridColDef[] = [
        {
            field: 'base_model_file',
            headerName: translatedColumnNames.base_model_file,
            type: 'string',
            minWidth: 130,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.base_model_file.name || ''}`,
        },
        {
            field: 'created_model_file',
            headerName: translatedColumnNames.created_model_file,
            type: 'string',
            minWidth: 130,
            flex: 1,
            align: 'left',
            headerAlign: 'left',
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.created_model_file?.name ? params.row.created_model_file.name : t("no_ifc_created_yet")}`,
        },
        {
            field: 'creation_level',
            headerName: translatedColumnNames.creation_level,
            type: 'string',
            minWidth: 130,
            flex: 0.8,
            align: 'left',
            headerAlign: 'left',
            valueGetter: (params: GridValueGetterParams) =>
                `${ifcModelCreationsLevels.find((state: IIfcCreationLevelChoice) => state.value === params.row["creation_level"]).label}`,
        },
        {
            field: 'creation_status',
            headerName: translatedColumnNames.creation_status,
            type: 'string',
            minWidth: 80,
            flex: 0.4,
            align: 'left',
            headerAlign: 'left',
            valueGetter: (params: GridValueGetterParams) =>
                `${ifcModelCreationsStates.find((state: IIfcCreationStatusState) => state.value === params.row["creation_status"]).label}`,
        },
        {
            field: 'created_at',
            headerName: translatedColumnNames.created_at,
            type: 'string',
            minWidth: 100,
            flex: 0.6,
            align: 'left',
            headerAlign: 'left',
        },
        {
            field: 'created_by',
            headerName: translatedColumnNames.created_by,
            type: 'string',
            minWidth: 120,
            flex: 0.6,
            align: 'left',
            headerAlign: 'left',
            renderCell: ({ row }) => (
                <Stack direction="row" spacing={-2}>
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                                bgcolor: stringToColor(
                                    `${row["created_by"]["first_name"]} ${row["created_by"]["last_name"]}`
                                ),
                                width: 24,
                                height: 24,
                                fontSize: 17
                            }}
                        >
                            {`${row["created_by"]["first_name"].slice(0, 1)}${row["created_by"]["last_name"].slice(
                                0,
                                1
                            )}`}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={`${row["created_by"]["first_name"]} ${row["created_by"]["last_name"]}`}
                    />
                </Stack>
            )
        },
        {
            field: "action",
            headerName: translatedColumnNames.actions,
            width: 160,
            align: "center",
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) => (
                <Stack direction="row" alignItems="center">
                    {hasProjectPerm(userPermissions, [
                        projectPermissions.uploaded_files.maintainer,
                    ]) &&
                        <Box>
                            <Button
                                sx={{ mr: 1 }}
                                size="small"
                                startIcon={<SearchIcon />}
                                variant="contained"
                                onClick={() => {
                                    const ifcModelCreation = ifcModelCreations.find(ifcModelCreation => ifcModelCreation.id === row.creation_id);
                                    if (ifcModelCreation) {
                                        dispatch(selectIfcModelCreation(ifcModelCreation));
                                        dispatch(handleShowInfosIfcModelCreationsDialogOpen());
                                    }
                                }
                                }
                            >
                                Details</Button>
                            <Tooltip title={t<string>("actions.delete")}>
                                <IconButton onClick={() => handleDeleteIfcModelCreationButtonClick(row)} aria-label="delete" size="small">
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                </Stack>
            )
        },
    ];

    const tableRows = ifcModelCreations.map((creation: IIfcModelCreation, index: number): ITableRowData => {
        const created_at_format = new Date(creation.created_at);

        return ({
            id: index + 1,
            creation_id: creation.id,
            creation_level: creation.creation_level,
            creation_status: creation.creation_status,
            base_model_file: creation.base_model_file,
            created_model_file: creation.created_model_file,
            created_at: created_at_format.toLocaleString(),
            created_by: creation.created_by,
        })
    })

    useEffect(() => {
        if (!matches) {
            setColumnVisibilityModel(
                {
                    created_at: false,
                    created_by: false,
                    creation_level: true,
                    creation_status: true,
                    base_model_file: true,
                    created_model_file: false,
                    actions: true,
                }
            )
        } else {
            setColumnVisibilityModel(
                {
                    created_at: true,
                    created_by: true,
                    creation_level: true,
                    creation_status: true,
                    base_model_file: true,
                    created_model_file: true,
                    actions: true,
                }
            )
        }
    }, [matches]);

    useEffect(() => {
        if (projectId !== undefined) {
            dispatch(fetchIfcModelCreations(+projectId));
            dispatch(fetchIfcModelCreationsStatusStates(+projectId));
            dispatch(fetchIfcModelCreationsOptimizationPriorityChoices(+projectId));
            dispatch(fetchIfcModelCreationsLevelChoiches(+projectId));
            dispatch(fetchUploadedFiles(+projectId));
        }
    }, [projectId, locales]);

    return (
        <Container maxWidth={"lg"} sx={{ height: `calc(100vh - ${theme.topNavigation.appbar.height}px)` }}>
            <Box sx={{
                height: "100%",
                flexGrow: 1,
                pt: 4,
            }}>
                <DataGrid
                    rows={tableRows}
                    columns={tableColumns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 20, 50, 100]}
                    checkboxSelection={false}
                    disableRowSelectionOnClick
                    disableColumnMenu
                    columnVisibilityModel={columnVisibilityModel}
                    slots={{
                        loadingOverlay: LoadingSkeletonOverly,
                        noRowsOverlay: NoRowsOverlay,
                    }}
                    loading={loading.isLoading && loading.type == "fetch"}
                    autoHeight
                    sx={{ '--DataGrid-overlayHeight': '300px' }}
                />
                {
                    hasProjectPerm(userPermissions, [
                        projectPermissions.uploaded_files.maintainer,
                    ]) && <AddButton />
                }
            </Box>
            <CreateIfcModelCreationsDialog />
            <IfcModelCreationDeleteDialog />
            <ShowInfosIfcModelCreationsDialog />
            <AlertSnackbar
                localeUrl={"ifc_model_creations"}
                localeUrlKeyPrefix={"snackbar"}
                getSnackbar={getAlertSnackbar}
                closeSnackbar={closeAlertSnackbar}
            />
            <LoadingSnackbar
                localeUrl={"ifc_model_creations"}
                localeUrlKeyPrefix={"snackbar"}
                getSnackbar={getLoadingSnackbar}
                closeSnackbar={closeLoadingSnackbar}
                getLoading={getIfcModelCreationsLoading}
            />
        </Container>
    );
};

export default IfcModedlCreations;
