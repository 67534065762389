import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import Container from '@mui/material/Container'
import Toolbar from '@mui/material/Toolbar'
import { TransitionProps } from '@mui/material/transitions'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import Slide from '@mui/material/Slide'
import Zoom from '@mui/material/Zoom'
import { useAppDispatch, useAppSelector } from 'store'
import { getUploadedFilesDialogs, handleAddUploadedFilesDialogClose } from 'store/ui/projects/uploadedFiles/uploadedFiles.slice'
import { addUploadedFile, downloadUploadedFile, downloadUploadedFileToIndexedDB, fetchUploadedFiles } from 'store/entities/projects/uploadedFiles/uploadedFiles.actions'
import { useParams } from 'react-router-dom'
import { IFileValidationResponse, IUploadedFile, IUploadedFileTableRowData } from 'types'
import uuid from 'react-uuid';
import { useIndexedDB } from 'services/indexeddb'
import { IIndexedDBFiles, IndexedDBConfig } from 'services/indexeddb/indexeddbConfig'
import { fileToSha256Hex } from 'common/utils/helpers/getFileHash.helpers'
import { closeSelectModelDialog, getSelectModelDialog, selectModel, setModelFetchingFailed, setModelFetchingFullfilled, setModelFetchingStarted } from 'store/ui/projects/ifcViewer/ifcViewer.slice'
import Table from './table/table'
import { GridRowSelectionModel } from '@mui/x-data-grid'
import { getLocales } from 'store/entities/settings/settings.slice'
import { getDownloadedFileClientUrl, getUploadedFiles } from 'store/entities/projects/uploadedFiles/uploadedFiles.slice'


const ZoomTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Zoom ref={ref} {...props} />
})

const SlideTransition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} {...props} />
})

interface IProps {
  setModelFile: React.Dispatch<React.SetStateAction<File | null>>;
}

const SelectModelDialog: React.FC<IProps> = ({ setModelFile }) => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const { t } = useTranslation('ifc_viewer', {
    keyPrefix: 'dialogs.select_dialog',
  })
  const locales = useAppSelector(getLocales);
  const { projectId } = useParams();
  const { add, getLatestRecord, getAll, deleteRecord } = useIndexedDB("files");

  const [selectedModel, setSelectedModel] = useState<IUploadedFileTableRowData[]>([]);

  const open = useAppSelector(getSelectModelDialog).open
  const uploadedFiles = useAppSelector(getUploadedFiles);

  const handleDialogClose = () => {
    dispatch(closeSelectModelDialog());
  }

  const handleDialogLoadModel = () => {
    handleDialogClose()
    try {
      if (projectId !== undefined && selectedModel.length !== 0) {
        dispatch(setModelFetchingStarted(selectedModel[0].name));
        getAll().then(async (dbItems: IIndexedDBFiles[]) => {
          let fileFound = false;
          for (const dbItem of dbItems) {
            if (dbItem.uuid === selectedModel[0].uuid) {
              console.log(`Model ${dbItem.name} with ${dbItem.uuid} found in IndexedDB`)
              const uploadedFile: IUploadedFile = uploadedFiles.filter((file: IUploadedFile) => file.uuid === dbItem.uuid)[0]
              const hash = await fileToSha256Hex(dbItem.file);
              if (uploadedFile !== undefined && hash === uploadedFile.file_sha256_hex_hash) {
                console.log(`Model ${dbItem.name} with ${dbItem.uuid} checksum verified`);
                dispatch(selectModel({ name: dbItem.name, uuid: dbItem.uuid }));
                setModelFile(dbItem.file);
                fileFound = true;
                break;
              } else {
                console.log(`Model ${dbItem.name} with ${dbItem.uuid} checksum failed`);
                fileFound = false;
                console.log(`Delete Model ${dbItem.name} with ${dbItem.uuid} from IndexedDB`);
                await deleteRecord(dbItem.id);
                break;
              }
            }
          }
          if (fileFound === false) {
            console.log(`Model ${selectedModel[0].name} with ${selectedModel[0].uuid} not found in IndexedDB`)
            dispatch(downloadUploadedFileToIndexedDB(
              +projectId,
              selectedModel[0].file_id,
              selectedModel[0].name,
              selectedModel[0].uuid,
              add,
            )).then(() => {
              getLatestRecord().then((dbItem: any) => {
                if (dbItem.uuid === selectedModel[0].uuid) {
                  dispatch(selectModel({ name: dbItem.name, uuid: dbItem.uuid }))
                  setModelFile(dbItem.file)
                }
              })
            })
          }
        })
        dispatch(setModelFetchingFullfilled());
      } else {
        dispatch(setModelFetchingFailed());
      }
    } catch (error) {
      dispatch(setModelFetchingFailed());
    }
  }

  const isLoadingButtonDisabled = (): boolean => {
    if (selectedModel.length !== 0) {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    if (projectId !== undefined) {
      dispatch(fetchUploadedFiles(+projectId));
    }
  }, [projectId, locales]);

  return (
    <Container>
      <Dialog
        fullScreen={matches ? false : true}
        open={open}
        TransitionComponent={matches ? ZoomTransition : SlideTransition}
        keepMounted
        onClose={handleDialogClose}
        aria-describedby='dialog-slide-edit'
        maxWidth={"xl"}
      >
        <form
          id='select-model-form'
        >
          {matches ? (
            <DialogTitle>
              {t('dialog_title')}
            </DialogTitle>
          ) : (
            <AppBar sx={{ position: 'relative' }} color='inherit'>
              <Toolbar>
                <IconButton
                  edge='start'
                  color='inherit'
                  onClick={handleDialogClose}
                  aria-label='cancel'
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                  {t('dialog_title')}
                </Typography>
                <Button
                  autoFocus
                  color='inherit'
                  onClick={handleDialogLoadModel}
                  id='element-select-form'
                >
                  {t('load')}
                </Button>
              </Toolbar>
            </AppBar>
          )}
          <DialogContent>
            <Table setSelectedModel={setSelectedModel} />
          </DialogContent>
          <DialogActions>
            {matches && (
              <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: '100%' }}>
                <Button onClick={handleDialogClose}>{t("cancel")}</Button>
                <Button onClick={handleDialogLoadModel} disabled={isLoadingButtonDisabled()}>{t('load')}</Button>
              </Box>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  )
}

export default SelectModelDialog
