import type { IPermissionName } from "types";

export const projectPermissions = {
  dashboard: {
    read_only: <IPermissionName>"Project read only",
    maintainer: <IPermissionName>"Project maintainer",
  },
  stakeholder: {
    read_only: <IPermissionName>"Project stakeholder read only",
    maintainer: <IPermissionName>"Project stakeholder maintainer",
  },
  component_catalogue: {
    read_only: <IPermissionName>"Project component catalogue read only",
    maintainer: <IPermissionName>"Project component catalogue maintainer",
  },
  uploaded_files: {
    read_only: <IPermissionName>"Project uploaded files read only",
    maintainer: <IPermissionName>"Project uploaded files maintainer",
  },
};
