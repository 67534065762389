import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { IIfcModelCreation } from "types";

interface IGeneralResultTabProps {
    ifcModelCreation: IIfcModelCreation;
}

const GeneralResultTab: React.FC<IGeneralResultTabProps> = ({ ifcModelCreation }) => {
    return (
        <Box sx={{ mb: 2 }}>
            <Typography variant="h6" >Created model file</Typography>
            {/* <Divider sx={{ width: "100%" }} /> */}
            <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                    <Typography variant="body1" color="GrayText">name</Typography>
                    <Typography variant="body1" color="GrayText">creation level</Typography>
                    <Typography variant="body1" color="GrayText">uuid</Typography>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="body1">{ifcModelCreation.created_model_file?.name}</Typography>
                    <Typography variant="body1">{ifcModelCreation.created_model_file?.creation_level ? ifcModelCreation.created_model_file.creation_level : "none"}</Typography>
                    <Typography variant="body1">{ifcModelCreation.created_model_file?.uuid}</Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default GeneralResultTab;