import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { IIfcModelCreation } from "types";

interface IRequestDetailsTab {
    ifcModelCreation: IIfcModelCreation
}

const RequestDetailsTab: React.FC<IRequestDetailsTab> = ({ ifcModelCreation }) => {
    return (
        <Box>
            <Typography variant="h4">Request details</Typography>
            <Divider sx={{ width: "100%", mb: 5 }} />
            <Box sx={{ mb: 2 }}>
                <Typography variant="h6">Base model file</Typography>
                {/* <Divider sx={{ width: "100%" }} /> */}
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                        <Typography variant="body1" color="GrayText">name</Typography>
                        <Typography variant="body1" color="GrayText">creation level</Typography>
                        <Typography variant="body1" color="GrayText">uuid</Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="body1">{ifcModelCreation.base_model_file.name}</Typography>
                        <Typography variant="body1">{ifcModelCreation.base_model_file.creation_level ? ifcModelCreation.base_model_file.creation_level : "none"}</Typography>
                        <Typography variant="body1">{ifcModelCreation.base_model_file.uuid}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Typography variant="h6" >Creation optimization priority</Typography>
                {/* <Divider sx={{ width: "100%" }} /> */}
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {ifcModelCreation.creation_optimization_priority.map((priority, index) => {
                        if (priority.priority !== 0) {
                            return (
                                <Box key={index} sx={{ display: "flex", flexDirection: "row" }}>
                                    <Box sx={{ display: "flex", flexDirection: "column", mr: 3 }}>
                                        <Typography variant="body1" color="GrayText">topic</Typography>
                                        <Typography variant="body1" color="GrayText">priority</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Typography variant="body1">{priority.topic}</Typography>
                                        <Typography variant="body1">{priority.priority}</Typography>
                                    </Box>
                                </Box>
                            );
                        } return null;
                    })}
                </Box>
            </Box>
        </Box>
    );
}

export default RequestDetailsTab;