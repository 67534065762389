import { Avatar, Box, Container, IconButton, LinearProgress, ListItemAvatar, ListItemText, Skeleton, Stack, Tooltip, useMediaQuery } from "@mui/material";
import { AddButton } from "./buttons/addButton";
import { DataGrid, GridColDef, GridColumnVisibilityModel, GridRowHeightParams } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles'
import { useAppDispatch, useAppSelector } from "store";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getLoading, getUploadedFiles } from "store/entities/projects/uploadedFiles/uploadedFiles.slice";
import { getProjectPermissions } from "store/entities/projects/project.slice";
import { getLocales } from "store/entities/settings/settings.slice";
import { IStakeholderPermission, IUploadedFile } from "types";
import { downloadUploadedFile, fetchUploadedFiles } from "store/entities/projects/uploadedFiles/uploadedFiles.actions";
import { useTranslation } from "react-i18next";
import { getReadablyFileSize } from "common/utils/helpers/fileSize.helpers";
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { stringToColor } from "common/utils/helpers/stringToColor.helpers";
import { useState } from "react";
import { hasProjectPerm } from "services/authService";
import { projectPermissions } from "common/utils/constants/auth.constants";
import UploadedFileUploadDialog from "./dialogs/upload/uploadDialog";
import UploadedFileDeleteDialog from "./dialogs/delete/deleteDialog";
import {
  closeAlertSnackbar,
  closeLoadingSnackbar,
  getAlertSnackbar,
  getLoadingSnackbar,
  handleDeleteUploadedFilesDialogOpen,
  selectUploadedFile
} from "store/ui/projects/uploadedFiles/uploadedFiles.slice";
import { LoadingSkeletonOverly, NoRowsOverlay } from "./table/overlays";
import AlertSnackbar from "../../../../common/components/snackbars/alertSnackbar";
import LoadingSnackbar from "common/components/snackbars/loadingSnackbar";


const UploadedFiles: React.FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme()
  const { t } = useTranslation("uploaded_files", {
    keyPrefix: "list.table",
  });

  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});

  const uploadedFiles = useAppSelector(getUploadedFiles);
  const loading = useAppSelector(getLoading);
  const locales = useAppSelector(getLocales);
  const userPermissions: IStakeholderPermission[] = useAppSelector(
    getProjectPermissions
  );

  const { projectId } = useParams();

  interface ITableRowData {
    id: number;
    file_id: number;
    name: string;
    created_at: string;
    uploaded_by: {
      email: string;
      first_name: string;
      last_name: string;
    };
    size: string;
  }

  const handleDownloadFileButtonClick = (rowData: ITableRowData) => {
    if (projectId !== undefined) {
      const index = uploadedFiles.findIndex(
        (uploadedFile: IUploadedFile) => uploadedFile.id === rowData.file_id
      );
      dispatch(selectUploadedFile(uploadedFiles[index]));
      dispatch(downloadUploadedFile(+projectId, rowData.file_id, rowData.name))
    }
  }

  const handleDeleteFileButtonClick = (rowData: ITableRowData) => {
    if (projectId !== undefined) {
      const index = uploadedFiles.findIndex(
        (uploadedFile: IUploadedFile) => uploadedFile.id === rowData.file_id
      );
      dispatch(selectUploadedFile(uploadedFiles[index]));
      dispatch(handleDeleteUploadedFilesDialogOpen());
    }
  }

  const translatedColumnNames = {
    id: t("column_headers.id"),
    name: t("column_headers.name"),
    created_at: t("column_headers.created_at"),
    uploaded_by: t("column_headers.uploaded_by"),
    size: t("column_headers.size"),
    actions: t("column_headers.actions"),
  }

  const tableColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: translatedColumnNames.id,
      type: 'number',
      width: 70,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'name',
      headerName: translatedColumnNames.name,
      type: 'string',
      minWidth: 200,
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'created_at',
      headerName: translatedColumnNames.created_at,
      type: 'string',
      minWidth: 100,
      flex: 0.5,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'uploaded_by',
      headerName: translatedColumnNames.uploaded_by,
      type: 'string',
      minWidth: 100,
      flex: 0.5,
      align: 'left',
      headerAlign: 'left',
      renderCell: ({ row }) => (
        <Stack direction="row" spacing={-2}>
          <ListItemAvatar>
            <Avatar
              sx={{
                bgcolor: stringToColor(
                  `${row["uploaded_by"]["first_name"]} ${row["uploaded_by"]["last_name"]}`
                ),
                width: 24,
                height: 24,
                fontSize: 17
              }}
            >
              {`${row["uploaded_by"]["first_name"].slice(0, 1)}${row["uploaded_by"]["last_name"].slice(
                0,
                1
              )}`}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${row["uploaded_by"]["first_name"]} ${row["uploaded_by"]["last_name"]}`}
          />
        </Stack>
      )
    },
    {
      field: 'size',
      headerName: translatedColumnNames.size,
      type: 'string',
      minWidth: 100,
      flex: 0.2,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: "action",
      headerName: translatedColumnNames.actions,
      width: 100,
      align: "center",
      headerAlign: 'center',
      sortable: false,
      renderCell: ({ row }) => (
        <Stack direction="row" alignItems="center">
          <Tooltip title={t<string>("actions.download")}>
            <IconButton onClick={() => handleDownloadFileButtonClick(row)} aria-label="delete" size="small">
              <DownloadIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          {hasProjectPerm(userPermissions, [
            projectPermissions.uploaded_files.maintainer,
          ]) &&
            <Tooltip title={t<string>("actions.delete")}>
              <IconButton onClick={() => handleDeleteFileButtonClick(row)} aria-label="delete" size="small">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          }
        </Stack>
      )
    },
  ];

  const tableRows = uploadedFiles.map((file: IUploadedFile, index: number): ITableRowData => {
    const created_at_format = new Date(file.created_at);
    const filzeSize = getReadablyFileSize(file.size);

    return ({
      id: index + 1,
      file_id: file.id,
      name: file.name,
      created_at: created_at_format.toLocaleString(),
      uploaded_by: file.uploaded_by,
      size: filzeSize,
    })
  })

  useEffect(() => {
    if (!matches) {
      setColumnVisibilityModel(
        {
          id: false,
          created_at: false,
          uploaded_by: false,
          size: false,
        }
      )
    } else {
      setColumnVisibilityModel(
        {
          id: true,
          created_at: true,
          uploaded_by: true,
          size: true,
        }
      )
    }
  }, [matches]);

  useEffect(() => {
    if (projectId !== undefined) {
      dispatch(fetchUploadedFiles(+projectId));
    }
  }, [projectId, locales]);

  return (
    <Container maxWidth={"lg"} sx={{ height: `calc(100vh - ${theme.topNavigation.appbar.height}px)` }}>
      <Box sx={{
        height: "100%",
        flexGrow: 1,
        pt: 4,
      }}>
        <DataGrid
          rows={tableRows}
          columns={tableColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 20, 50, 100]}
          disableRowSelectionOnClick
          disableColumnMenu
          columnVisibilityModel={columnVisibilityModel}
          slots={{
            loadingOverlay: LoadingSkeletonOverly,
            noRowsOverlay: NoRowsOverlay,
          }}
          loading={loading.isLoading && loading.type == "fetch"}
          autoHeight
          sx={{ '--DataGrid-overlayHeight': '300px' }}
        />
        {
          hasProjectPerm(userPermissions, [
            projectPermissions.uploaded_files.maintainer,
          ]) && <AddButton />
        }
      </Box>
      <UploadedFileUploadDialog />
      <UploadedFileDeleteDialog />
      <AlertSnackbar
        localeUrl={"uploaded_files"}
        localeUrlKeyPrefix={"snackbar"}
        getSnackbar={getAlertSnackbar}
        closeSnackbar={closeAlertSnackbar}
      />
      <LoadingSnackbar
        localeUrl={"uploaded_files"}
        localeUrlKeyPrefix={"snackbar"}
        getSnackbar={getLoadingSnackbar}
        closeSnackbar={closeLoadingSnackbar}
        getLoading={getLoading}
      />

    </Container>
  );
};

export default UploadedFiles;
