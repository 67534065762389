import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTheme } from '@mui/material/styles'
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "store";
import { getIfcModelCreation, getIfcModelCreationsDialogs, handleShowInfosIfcModelCreationsDialogClose } from "store/ui/projects/ifcModelCreations/ifcModelCreations.slice";
import { DialogTitle, IconButton, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { IIfcModelCreation } from "types";
import Header from "./header/header";
import DetailTabs from "./detailTabs/detailTabs";
import { fetchConstructionMaterials } from "store/entities/projects/componentCatalogue/componentCatalogue.actions";

const ShowInfosIfcModelCreationsDialog: React.FC = () => {
    const { t } = useTranslation("ifc_model_creations", {
        keyPrefix: "dialogs.show_infos_dialog",
    });
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useAppDispatch();
    const { projectId } = useParams();

    const open = useAppSelector(getIfcModelCreationsDialogs).showDetailsIfcModelCreation;
    const selectedIfcModelCreation: IIfcModelCreation = useAppSelector(getIfcModelCreation);

    useEffect(() => {
        if (projectId !== undefined) {
            dispatch(fetchConstructionMaterials());
        }
    }, [projectId]);

    return (
        <Dialog
            fullScreen={fullScreen}

            fullWidth
            maxWidth={"xl"}
            open={open}
            onClose={() => (
                dispatch(handleShowInfosIfcModelCreationsDialogClose())
            )}
        >
            <DialogTitle>
                <Header ifcModelCreation={selectedIfcModelCreation} />
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => dispatch(handleShowInfosIfcModelCreationsDialogClose())}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}

            >
                <CloseIcon />
            </IconButton>
            <Box>
                <DialogContent>
                    <DetailTabs ifcModelCreation={selectedIfcModelCreation} />
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Box>
        </Dialog>
    );
};
export default ShowInfosIfcModelCreationsDialog;
